import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, interval, of } from 'rxjs';
import { takeUntil, map, shareReplay, tap, delay, first } from 'rxjs/operators';

import { SnackBarComponent } from '@nexus/components/snack-bar/snack-bar.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationComponent } from '@nexus/components/notification/notification.component';
import { TranslocoService } from '@jsverse/transloco';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  durationInSeconds = 3;
  notificationInProgress = false;

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private translocoService: TranslocoService,
  ) {}

  notify(message: string, action?: string): void {
    if (this.notificationInProgress || !message || message === '') {
      return;
    }
    of(true)
      .pipe(
        delay(this.durationInSeconds * 2500),
        first(),
        tap(() => {
          this.notificationInProgress = false;
        }),
      )
      .subscribe();
    this.notificationInProgress = true;
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: this.durationInSeconds * 2500,
      data: { message, action },
    });
  }

  notifyi18n(key: string): void {
    this.notify(this.translocoService.translate(key));
  }

  showBlockDialog(message: string, link: string) {
    this.dialog.open(NotificationComponent, {
      width: '800px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        message,
        link,
      },
    });
  }
}
